import { useNavigate } from '@remix-run/react';
import capitalize from 'lodash/capitalize';
import path from 'path';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { type SWRResponse } from 'swr';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  EnumsExConnectType,
} from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useLocationHref } from '../../hooks/useLocationHref';
import { useTitle } from '../../hooks/useTitle';
import { safeWindowReload } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { OrganizerRoleUtils, type OrgConnection } from '../../types';
import { assertExhaustive, err2s, makeTitle } from '../../utils/common';
import { type Action, type ActionButton, ActionSheet } from '../ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ArrowRightIcon } from '../icons/Arrows';
import { DeleteIcon } from '../icons/DeleteIcon';
import { InfoIcon } from '../icons/InfoIcon';
import { SlackIcon } from '../icons/SlackIcon';
import { TeamsIcon } from '../icons/TeamsIcon';
import { Loading } from '../Loading';
import { useOrgConnection } from '../Organization/hooks/useOrgConnection';
import { OrgConnection as OrgConnectionWidget } from '../Organization/OrgConnection';
import { useOrgFeatureContext } from '../Organization/OrgFeatureProvider';
import { OrgUtils } from '../Organization/utils';
import { Navbar, useProgramPicker } from '../Program';
import { useBasicProgramFromLink } from '../Program/usePrograms';
import { ProgramUtils } from '../Program/utils';
import { SlackInstallButton } from '../Slack';
import { useUser } from '../UserContext';
import { ChannelMemberAvatar } from './ChannelMemberAvatar';
import { CreateChannelModal } from './CreateChannelModal';
import { useChannels } from './hooks/useChannels';
import { useSlackInstalledCallbackModal } from './Slack/useSlackInstalledCallbackModal';
import { ChannelUtils } from './utils';

type ConnnectionActionSheetKeys = 'disconnect';

function ConnnectionActionSheet(props: {
  trigger: HTMLElement | undefined;
  onClick: (key: ConnnectionActionSheetKeys) => void;
}): JSX.Element {
  const { onClick } = props;

  function buildAction(
    action: Omit<ActionButton<ConnnectionActionSheetKeys>, 'onClick'>
  ): ActionButton<ConnnectionActionSheetKeys> {
    return {
      ...action,
      onClick: () => {
        onClick(action.key);
      },
    };
  }
  const actions: Action<ConnnectionActionSheetKeys>[] = [];

  actions.push(
    buildAction({
      kind: 'button',
      key: 'disconnect',
      icon: <DeleteIcon />,
      className: 'text-red-002',
      text: 'Disconnect Workspace',
    })
  );

  return (
    <ActionSheet actions={actions} trigger={props.trigger} placement='bottom' />
  );
}

function Connection(props: {
  conn: Nullable<OrgConnection>;
  error: unknown;
  isValidating: boolean;
  createChannelConfig?: {
    enabled?: boolean;
    onComplete?: () => void;
  };
}): JSX.Element {
  const { conn, error: loadError, isValidating, createChannelConfig } = props;
  const { org, adminMode } = useOrgFeatureContext();
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const ref = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let id: ReturnType<typeof setInterval>;
    function run() {
      if (!ref.current) {
        id = setTimeout(run);
      } else {
        forceUpdate();
      }
    }
    run();
    return () => {
      if (id) clearTimeout(id);
    };
  }, [forceUpdate, isValidating]);

  const {
    state: { transformed: disonnectState },
    error: disconnectError,
    call: disconnect,
  } = useAsyncCall(
    useCallback(async () => {
      return await apiService.organization.disconnect(org.id);
    }, [org.id])
  );

  if (isValidating) return <Loading text='' />;

  if (disonnectState.isRunning) return <Loading text='Disconnecting' />;

  const error = loadError || disconnectError;

  if (error) {
    return <div className='text-red-002 text-xs'>{err2s(error)}</div>;
  }

  const onCreateChannel = () => {
    triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: (p) => (
        <>
          <CreateChannelModal
            orgId={org.id}
            onComplete={() => {
              p.internalOnConfirm();
              createChannelConfig?.onComplete?.();
            }}
            onCancel={p.internalOnCancel}
          />
        </>
      ),
    });
  };

  const onAction = async (key: ConnnectionActionSheetKeys) => {
    switch (key) {
      case 'disconnect':
        const confirmed = await triggerFullScreenModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='text-2xl font-medium text-white text-center'>
              Are you sure you want to disconnect this Workspace from Luna Park?
            </div>
          ),
          confirmBtnLabel: 'Confirm',
          confirmBtnVariant: 'delete',
          cancelBtnLabel: 'Cancel',
          containerClassName: 'bg-black bg-opacity-60',
          boxDimensionsClassName: 'w-86 h-60 px-4 py-2',
        });
        if (confirmed.result === 'canceled') return;
        const resp = await disconnect();
        if (!resp) return;
        safeWindowReload();
        break;
      default:
        assertExhaustive(key);
        break;
    }
  };

  if (!conn) {
    if (createChannelConfig?.enabled && adminMode) {
      return (
        <button
          type='button'
          className='btn-secondary h-12 w-50 font-medium'
          onClick={onCreateChannel}
        >
          Create LP Channel
        </button>
      );
    } else {
      return (
        <div className='text-secondary text-xs'>
          No Slack Workspace connected
        </div>
      );
    }
  }

  if (conn.status === 'inactive') {
    return (
      <div
        className={`text-xs ${adminMode ? 'text-red-002' : 'text-secondary'}`}
      >
        {adminMode ? 'Slack App Uninstalled' : 'No Slack Workspace connected'}
      </div>
    );
  }

  return (
    <div>
      <div
        ref={ref}
        className={`w-45 h-12 ${adminMode ? 'cursor-pointer' : ''}`}
      >
        <OrgConnectionWidget org={org} immutable />
      </div>
      {ref.current && adminMode && (
        <ConnnectionActionSheet trigger={ref.current} onClick={onAction} />
      )}
    </div>
  );
}

export function Index(): JSX.Element | null {
  const { org, adminMode } = useOrgFeatureContext();
  useTitle(makeTitle(`Channels for ${org.name}`));
  const swr = useChannels(org.id);
  const user = useUser();

  const { connection, error, isValidating } = useOrgConnection({
    orgId: org.id,
    immutable: true,
  });

  const openProgramPicker = useProgramPicker();

  useSlackInstalledCallbackModal();

  const numOfChannels = swr.data?.length ?? 0;
  const manageable =
    connection?.status === 'active' &&
    numOfChannels > 0 &&
    !adminMode &&
    OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);

  if (isValidating) return null;

  return (
    <section className='w-full'>
      {!adminMode && (
        <Navbar
          showConnectToSlack={!adminMode && connection?.status !== 'active'}
        />
      )}
      <header className='w-full flex items-center justify-between mb-10'>
        {adminMode ? (
          <div className='flex items-center justify-center'>
            {connection?.type === EnumsExConnectType.ExConnectTypeTeams && (
              <TeamsIcon className='w-6 h-6 mr-2' />
            )}
            {connection?.type === EnumsExConnectType.ExConnectTypeSlack && (
              <SlackIcon className='w-6 h-6 mr-2' />
            )}
            <div className='text-2xl font-medium'>
              {org.name}'s {capitalize(connection?.type)} Channels
            </div>
          </div>
        ) : (
          <div>
            <div className='text-white text-3.5xl font-bold'>
              My Activated Programs
            </div>
            <div className='text-icon-gray text-xl font-medium'>
              Luna Park channels in your workspace and their associated Programs
            </div>
          </div>
        )}

        <Connection
          conn={connection}
          error={error}
          isValidating={isValidating}
          createChannelConfig={{
            enabled: true,
            onComplete: () => swr.mutate(),
          }}
        />
      </header>
      <main>
        {manageable && (
          <div className='flex flex-row-reverse'>
            <button
              type='button'
              className='btn-secondary h-10 px-4'
              onClick={() => openProgramPicker(org)}
            >
              + Start a New Program
            </button>
          </div>
        )}
        <div className='w-full text-white flex justify-center pt-10'>
          <Main swr={swr} connection={connection} />
        </div>
      </main>
    </section>
  );
}

function Main(props: {
  connection: Nullable<OrgConnection>;
  swr: SWRResponse<DtoChannel[], unknown>;
}): JSX.Element | null {
  const { connection, swr } = props;
  const { org, adminMode } = useOrgFeatureContext();
  const openProgramPicker = useProgramPicker();
  const locationHref = useLocationHref();

  useEffectOnce(() => {
    swr.mutate();
  });

  const state = useMemo(() => {
    if (!connection || connection.status === 'inactive') return 'not-connected';
    if (swr.isLoading) return 'loading';
    if (swr.error) return 'error';
    if (swr.data && swr.data.length === 0) return 'empty';
    return 'ready';
  }, [connection, swr.data, swr.error, swr.isLoading]);

  switch (state) {
    case 'not-connected':
      return adminMode ? (
        swr.data ? (
          <ChannelList channels={swr.data} />
        ) : null
      ) : (
        <div className='flex flex-col items-center justify-center gap-10'>
          <div className='text-2xl font-medium'>
            Please add Luna Park to your Slack workspace first!
          </div>
          <SlackInstallButton from={locationHref} />
        </div>
      );
    case 'loading':
      return <Loading />;
    case 'error':
      return <div className='text-sms text-red-001'>{err2s(swr.error)}</div>;
    case 'empty':
      return adminMode ? (
        <div className='text-2xl font-medium'>
          No Luna Park Channels created yet.
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center gap-10'>
          <div className='text-2xl font-medium text-center'>
            Start a new Program to add a Luna Park Channel <br />
            to {org.name} Slack workspace!
          </div>
          <button
            type='button'
            className='btn-primary h-10 px-4'
            onClick={() => openProgramPicker(org)}
          >
            Start a New Program
          </button>
        </div>
      );
    case 'ready':
      return swr.data ? <ChannelList channels={swr.data} /> : null;
    default:
      assertExhaustive(state);
      return null;
  }
}

function ChannelList(props: { channels: DtoChannel[] }): JSX.Element {
  const { channels } = props;

  const sorted = channels.sort((a, b) => {
    // 1. order by active,inactive
    const aActive = a.status === 'active' ? 1 : 0;
    const bActive = b.status === 'active' ? 1 : 0;
    if (aActive !== bActive) return bActive - aActive;
    const aHasActiveProgramLinks =
      ChannelUtils.ActiveProgramLinks(a).length > 0 ? 1 : 0;
    const bHasActiveProgramLinks =
      ChannelUtils.ActiveProgramLinks(b).length > 0 ? 1 : 0;
    if (aHasActiveProgramLinks !== bHasActiveProgramLinks)
      return bHasActiveProgramLinks - aHasActiveProgramLinks;
    // 2. order by createdAt
    return b.createdAt.localeCompare(a.createdAt);
  });
  return (
    <div className='grid grid-cols-2 gap-4 w-full'>
      {sorted.map((c) => (
        <ChannelItem key={c.id} channel={c} />
      ))}
    </div>
  );
}

function ChannelItem(props: { channel: DtoChannel }): JSX.Element {
  const { channel } = props;
  const { org } = useOrgFeatureContext();
  const connTypeInfo = OrgUtils.GetConnTypeInfo(org, channel.exConnectType);
  const Icon = connTypeInfo.icon;
  const creator = channel.creator;

  const noActiveProgramLinks =
    ChannelUtils.ActiveProgramLinks(channel).length === 0;
  const inactive = channel.status === 'inactive';

  return (
    <section className='flex flex-col gap-2 border border-secondary rounded-xl bg-modal w-full p-4'>
      <header
        className={`flex items-center justify-between ${
          inactive && 'opacity-40'
        }`}
      >
        <div className='flex items-center'>
          <div className='w-5 h-5 mr-3'>
            <Icon />
          </div>
          <div className='font-bold'>
            {ChannelUtils.ChannelName(channel, org)}
          </div>
          {(inactive || noActiveProgramLinks) && (
            <div className='text-sms ml-3'>
              {inactive ? 'Detached' : 'No Programs'}
            </div>
          )}
        </div>
        <div className='text-sms'>{channel.membersCount} People</div>
      </header>
      <div className='flex items-center justify-start'>
        <ChannelMemberAvatar
          exConnectType={channel.exConnectType}
          hideIfNoExConnection
          member={channel.creator}
        />
        <div
          className={`flex flex-col items-start ${inactive && 'opacity-40'}`}
        >
          <div className='text-sms'>
            {!!channel.exConnectType
              ? `${creator.fullName} (Owner)`
              : 'Created by Luna Park'}
          </div>
        </div>
      </div>
      <ProgramList channel={channel} />
    </section>
  );
}

function ProgramList(props: { channel: DtoChannel }): JSX.Element | null {
  const { channel } = props;
  if (channel.status === 'inactive') return null;
  const activeProgramLinks = channel.programLinks.filter(
    (l) => l.status === 'active'
  );

  return (
    <div className='flex flex-col flex-1 gap-3'>
      <div className='text-sms font-bold'>
        {activeProgramLinks.length > 0 && 'Programs'}
      </div>
      {activeProgramLinks.map((link) => (
        <ProgramEntry
          key={`${link.programType}-${link.programTargetId}`}
          channel={channel}
          programLink={link}
        />
      ))}
    </div>
  );
}

function ProgramEntry(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { programLink } = props;
  const { data: program } = useBasicProgramFromLink(programLink);
  const navigate = useNavigate();
  const { routePrefix } = useOrgFeatureContext();

  const handleClick = () => {
    navigate(path.join(routePrefix, ProgramUtils.BuildURL(programLink)));
  };

  const iconUrl = program?.basicSettings?.icon?.media?.url;
  return (
    <div
      className='w-full h-14 flex items-center gap-2 
      border border-secondary rounded-xl px-3 cursor-pointer'
      style={{
        backgroundColor: `${program?.basicSettings?.themeColor}`,
      }}
      onClick={handleClick}
    >
      {iconUrl && <img src={iconUrl} alt='icon' className='w-7 h-7 mr-2' />}
      <div>{program?.name}</div>
      {program?.hint && (
        <div title={program.hint}>
          <InfoIcon />
        </div>
      )}
      <div className='ml-auto'>
        <ArrowRightIcon />
      </div>
    </div>
  );
}
